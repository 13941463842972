<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab href="#ativos" @change="getAtivos">Ativos</v-tab>
      <v-tab href="#naoativos" @change="getNaoAtivos">Não ativos</v-tab>

      <v-tabs-items v-model="tabs">
        <v-tab-item value="ativos">
          <div class="mt-4">
            <v-data-table
              :headers="headers"
              :items="servicos"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:[`item.tipoTarifa`]="{ item }">
                <span style="text-transform: capitalize">{{
                  item.tipoTarifa
                }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Serviços ativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    medium
                    @click="() => getAtivos()"
                    title="Atualizar"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                    v-if="allowedToAdd"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="canCreate"
                        color="primary"
                        dark
                        class="mb-2"
                        v-on="on"
                        @click="
                          isEdit = false
                          form = {}
                        "
                      >
                        Novo serviço
                      </v-btn>
                    </template>
                    <cadastro-servico
                      formTitle="Cadastrar Serviço"
                      ref="cadastroServico"
                      :isEdit="isEdit"
                      :tarifas="tarifas"
                      v-model="form"
                      v-if="dialog"
                      @close="close"
                      @save="save"
                    />
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="!item.default && canUpdate"
                  title="Desativar Serviço"
                  @click="handleAction(item, 'desativar')"
                  small
                  class="mr-2"
                  color="red"
                  >mdi-cancel</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="addTarifa(item)"
                  >mdi-currency-usd</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editOrdem(item)"
                  >mdi-order-alphabetical-ascending</v-icon
                >
                <!-- <v-icon small @click="prepareDeleteItem(item)"
                  >mdi-delete</v-icon
                > -->
              </template>
              <template v-slot:no-data>
                <v-btn v-if="canList" color="primary" @click="() => getAtivos()"
                  >Reset</v-btn
                >
                <div v-if="!canList">
                  <span class="red--text"
                    >Você não tem permissão para visualizar as informações desta
                    tela</span
                  >
                </div>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab-item value="naoativos">
          <div class="mt-4">
            <v-data-table
              :headers="headers"
              :items="servicosNaoAtivos"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:[`item.tipoTarifa`]="{ item }">
                <span style="text-transform: capitalize">{{
                  item.tipoTarifa
                }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Serviços não ativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    medium
                    @click="() => getNaoAtivos()"
                    title="Atualizar"
                    >mdi-refresh</v-icon
                  >
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="!item.default && canUpdate"
                  title="Ativar Serviço"
                  @click="handleAction(item, 'ativar')"
                  small
                  class="mr-2"
                  color="red"
                  >mdi-undo-variant</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="addTarifa(item)"
                  >mdi-currency-usd</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon small @click="prepareDeleteItem(item)"
                  >mdi-delete</v-icon
                > -->
              </template>

              <template v-slot:no-data>
                <v-btn
                  v-if="canList"
                  color="primary"
                  @click="() => getNaoAtivos()"
                  >Reset</v-btn
                >
                <div v-if="!canList">
                  <span class="red--text"
                    >Você não tem permissão para visualizar as informações desta
                    tela</span
                  >
                </div>
              </template>
            </v-data-table>
          </div>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="v-card mb-4">
                <span class="headline">Deletar {{ itemDelete.descricao }}</span>
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="dialogDelete = false"
                  >Cancelar</v-btn
                >
                <!-- <v-btn
                  color="darken-1"
                  style="background: #f00; color: white"
                  @click="deleteItem"
                  >Deletar</v-btn
                > -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
      </v-tabs-items>
      <confirm-dialog @confirm="confirmDialog" />
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CadastroServico from './CadastroServico.vue'
import FileApi from '@/services/files/index'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: { CadastroServico, ConfirmDialog },
  data() {
    return {
      tabs: null,

      dialogDelete: false,
      confirmDialogItem: null,
      confirmDialogAction: '',
      itemDelete: {},
      dialog: false,
      isEdit: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Tipo de tarifa', value: 'tipoTarifa' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        id: null,
        descricao: null,
        foto: null,
        tipoTarifa: null,
        etapas: [],
      },
      form: {
        id: null,
        descricao: null,
        prestador: null,
        foto: null,
        tipoTarifa: null,
        etapas: [],
      },
      tarifas: [
        {
          tipo: 'eixo',
          name: 'Eixo',
        },
        {
          tipo: 'peso',
          name: 'Peso',
        },
        {
          tipo: 'hora',
          name: 'Hora',
        },
        {
          tipo: 'subproduto',
          name: 'Sub Produto',
        },
        {
          tipo: 'embalagem',
          name: 'Embalagem',
        },
      ],
    }
  },

  computed: {
    ...mapState('patio', ['servicos', 'servicosNaoAtivos']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Servicos.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo serviço' : 'Editar serviço'
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
  },

  async created() {
    if (this.canList) {
      this.getAtivos()
      this.setTarifas([])
    }
  },

  watch: {
    dialog(val) {
      val || this.close()

      if (!val) {
        Object.assign(this.form, this.defaultItem)
        this.isEdit = false
      }
    },
  },

  methods: {
    ...mapActions('patio', [
      'getServicosAtivos',
      'getServicosNaoAtivos',
      'addServico',
      'editServico',
      // 'deleteServico',
      'desativarServico',
      'ativarServico',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('patio', ['setServicos', 'setTarifas']),
    editOrdem(item) {
      console.log(item)
      this.$router.push({
        name: 'ordemEtapas',
        params: {
          servico: item,
        },
      })
    },
    async getAtivos() {
      if (this.canList) {
        await this.getServicosAtivos({
          proprietario: this.empresaAtual.public_id,
          ativo: true,
        })
      }
    },
    async getNaoAtivos() {
      if (this.canList) {
        await this.getServicosNaoAtivos({
          proprietario: this.empresaAtual.public_id,
          ativo: false,
        })
      }
    },

    handleAction(item, action) {
      this.confirmDialogItem = item
      this.confirmDialogAction = action

      this.setDialogMessage({
        title:
          action === 'remover'
            ? 'Remoção'
            : action === 'ativar'
            ? 'Ativação'
            : 'Desativação',
        message: `Deseja realmente ${action} o serviço/tarifa?`,
      })
    },

    async confirmDialog() {
      try {
        switch (this.confirmDialogAction) {
          case 'ativar':
            await this.ativarServico(this.confirmDialogItem)
            this.successMessage('Serviço ativado com sucesso!')
            break
          default:
            await this.desativarServico(this.confirmDialogItem)
            this.successMessage('Serviço desativado com sucesso!')
        }
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    addTarifa(item) {
      this.$emit('showTarifa', item)
    },

    editItem(item) {
      item.foto = null

      this.form = { ...item }
      this.dialog = true
      this.isEdit = true
    },

    close() {
      this.dialog = false
      this.isEdit = false
    },

    async save() {
      if (!this.$refs['cadastroServico'].validate()) return
      if (this.isEdit) {
        await this.edit()
      } else {
        await this.store()
      }

      this.form = { ...this.formDefault }
      this.$refs['cadastroServico'].close()
    },

    async store() {
      try {
        if (this.form.foto) {
          await this.validarFotoBiometria()
        } else {
          delete this.form.foto
        }

        await this.addServico({
          ...this.form,
          etapas: this.$refs.cadastroServico.listEtapa
            ? this.$refs.cadastroServico.listEtapa.map((etapa) => etapa.nome)
            : [],
          proprietario: this.empresaAtual.public_id,
        })
        this.isEdit = false
        this.successMessage('Serviço cadastrado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async edit() {
      try {
        if (this.form.foto) {
          await this.validarFotoBiometria()
        } else {
          delete this.form.foto
        }

        const data = { ...this.form }

        if (data.etapas) delete data.etapas
        await this.editServico(data)
        this.isEdit = false
        this.successMessage('Serviço atualizado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    // prepareDeleteItem(item) {
    //   this.dialogDelete = true
    //   this.itemDelete = item
    // },

    // async deleteItem() {
    //   await this.deleteServico(this.itemDelete.id)
    //   this.itemDelete = {}
    //   this.dialogDelete = false
    // },

    async validarFotoBiometria() {
      let formData = new FormData()
      formData.append('file', this.form.foto)
      try {
        const { attachment_key } = await FileApi.postFotoMotorista(formData)
        if (attachment_key) {
          this.form.foto = attachment_key
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  },
}
</script>
