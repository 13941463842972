var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{attrs:{"href":"#ativos"},on:{"change":_vm.getAtivos}},[_vm._v("Ativos")]),_c('v-tab',{attrs:{"href":"#naoativos"},on:{"change":_vm.getNaoAtivos}},[_vm._v("Não ativos")]),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"value":"ativos"}},[_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.servicos,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"item.tipoTarifa",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.tipoTarifa))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Serviços ativos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":function () { return _vm.getAtivos(); }}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),(_vm.allowedToAdd)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.isEdit = false
                        _vm.form = {}}}},on),[_vm._v(" Novo serviço ")]):_vm._e()]}}],null,false,3078315877),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('cadastro-servico',{ref:"cadastroServico",attrs:{"formTitle":"Cadastrar Serviço","isEdit":_vm.isEdit,"tarifas":_vm.tarifas},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(!item.default && _vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Desativar Serviço","small":"","color":"red"},on:{"click":function($event){return _vm.handleAction(item, 'desativar')}}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.addTarifa(item)}}},[_vm._v("mdi-currency-usd")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editOrdem(item)}}},[_vm._v("mdi-order-alphabetical-ascending")]):_vm._e()]}},{key:"no-data",fn:function(){return [(_vm.canList)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.getAtivos(); }}},[_vm._v("Reset")]):_vm._e(),(!_vm.canList)?_c('div',[_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]):_vm._e()]},proxy:true}],null,true)})],1)]),_c('v-tab-item',{attrs:{"value":"naoativos"}},[_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.servicosNaoAtivos,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"item.tipoTarifa",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.tipoTarifa))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Serviços não ativos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":function () { return _vm.getNaoAtivos(); }}},[_vm._v("mdi-refresh")]):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(!item.default && _vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Ativar Serviço","small":"","color":"red"},on:{"click":function($event){return _vm.handleAction(item, 'ativar')}}},[_vm._v("mdi-undo-variant")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.addTarifa(item)}}},[_vm._v("mdi-currency-usd")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"no-data",fn:function(){return [(_vm.canList)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.getNaoAtivos(); }}},[_vm._v("Reset")]):_vm._e(),(!_vm.canList)?_c('div',[_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]):_vm._e()]},proxy:true}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"v-card mb-4"},[_c('span',{staticClass:"headline"},[_vm._v("Deletar "+_vm._s(_vm.itemDelete.descricao))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1),_c('confirm-dialog',{on:{"confirm":_vm.confirmDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }