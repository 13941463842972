<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Serviços">
          <cadastro-tarifa v-if="showFormTarifa" :fornecedorPublicID="''" :servico="servico" />
          <listar-servico v-else @showTarifa="showTarifa" />
          <div class="pa-5 border-top text-right">
            <v-btn
              v-if="showFormTarifa"
              color="black"
              class="text-capitalize"
              dark
              @click="
                servico = {}
                showFormTarifa = false
              "
            >
              Voltar
            </v-btn>
          </div>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarServico from '@/components/patio/Servico/ListarServico'
import CadastroTarifa from '../../components/patio/Servico/Tarifas/CadastroTarifa.vue'

export default {
  data() {
    return {
      servico: {},
      showFormTarifa: false,
      page: {
        title: 'Gerenciamento de serviços',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Serviços',
          disabled: false,
        },
      ],
    }
  },
  methods: {
    showTarifa(item) {
      this.servico = item
      this.showFormTarifa = true
    },
  },
  components: {
    CadastroTarifa,
    ListarServico,
  },
}
</script>

<style></style>
