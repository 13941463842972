<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <validation-observer ref="observer">
        <form ref="form" class="container pa-0">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <v-text-field
                  v-model="value.nome"
                  :error-messages="errors"
                  ref="name"
                  label="Nome"
                  required
                  :readonly="value.default"
                  :disabled="value.default"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <validation-provider name="cobranca" rules="">
                <v-checkbox
                  v-model="value.cobranca"
                  ref="cobranca"
                  label="Cobrança?"
                  :readonly="value.default"
                  :disabled="value.default"
                ></v-checkbox>
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <validation-provider name="Ocupa Vaga?" rules="">
                <v-checkbox
                  v-model="value.ocupaVaga"
                  ref="Ocupa Vaga?"
                  label="Ocupa Vaga?"
                  :readonly="value.default"
                  :disabled="value.default"
                ></v-checkbox>
              </validation-provider>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '../../BaseModal/BaseModal.vue'
import ButtonsCadastro from '../../ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroServico',
  etapas: [],
  props: {
    formTitle: String,
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formError: false,
      dialogEtapa: false,
    }
  },

  methods: {
    async validate() {
      await this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    close() {
      Object.assign(this.value, this.defaultItem)
      this.$refs.observer.reset()
      this.$emit('close')
    },

    save() {
      this.$emit('save', this.value)

      this.$refs.observer.reset()
    },
  },
}
</script>
<style></style>
