<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <base-modal :formTitle="formTitle">
      <template v-slot:body>
        <form ref="form" class="container pa-0">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="serviço"
                rules="required"
              >
                <input
                  required
                  type="file"
                  ref="foto"
                  :error-messages="errors"
                  @change="changeArquivo"
                  accept="image/*"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="serviço"
                rules="required"
              >
                <v-text-field
                  v-model="value.descricao"
                  :error-messages="errors"
                  ref="descricao"
                  label="Descrição"
                  :hide-details="true"
                  :readonly="value.default"
                  :disabled="value.default"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="serviço"
                rules="required"
              >
                <v-select
                  :items="tarifas"
                  item-text="name"
                  item-value="tipo"
                  v-model="value.tipoTarifa"
                  :error-messages="errors"
                  :hide-details="true"
                  ref="tipoTarifa"
                  label="Tipo de tarifa"
                  required
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
                <v-autocomplete
                  v-model="value.prestador"
                  :items="prestadoresServico"
                  item-text="business_name"
                  item-value="public_id"
                  label="Prestador"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-checkbox
                v-model="value.exige_classificacao"
                label="Exige Classificação?"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-checkbox
                v-model="value.antecipado"
                label="Antecipado?"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="list-etapas">
              <label for="etapas">Etapas:</label>
              <div class="py-2 mt-2 etapa__header">
                <p>Posição</p>
                <p>Nome</p>
                <p>Ações</p>
              </div>
              <draggable v-model="listEtapa" @change="changeOrdemEtapa">
                <div v-for="(etapa, index) in listEtapa" :key="index">
                  <div class="py-2 etapa">
                    <p>{{ index + 1 }}</p>
                    <p>{{ etapa.nome }}</p>
                    <div v-if="!etapa.default">
                      <v-icon
                        v-if="isEdit"
                        small
                        class="mr-2"
                        @click="editItem(etapa)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon @click="removeEtapa(index)" small color="red"
                        >mdi-delete</v-icon
                      >
                    </div>
                  </div>
                </div>
              </draggable>
            </v-col>
            <v-dialog v-model="dialogEtapa" max-width="450px">
              <template v-slot:activator="{ on }">
                <v-btn class="button-submit-green d-flex mx-auto" v-on="on">
                  Adicionar etapa
                </v-btn>
              </template>
              <cadastro-etapa
                v-if="dialogEtapa"
                formTitle="Cadastrar Etapa"
                ref="cadastroEtapa"
                v-model="formEtapa"
                @close="closeEtapa"
                @save="saveEtapa"
              />
            </v-dialog>
          </v-row>
        </form>
      </template>
      <template v-slot:buttons>
        <buttons-cadastro @close="close" @save="save" :disabled="invalid" />
      </template>
    </base-modal>
  </validation-observer>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BaseModal from '../../BaseModal/BaseModal.vue'
import ButtonsCadastro from '../../ButtonsCadastro/ButtonsCadastro.vue'
import CadastroEtapa from './CadastroEtapa.vue'

import draggable from 'vuedraggable'

export default {
  components: { BaseModal, ButtonsCadastro, draggable, CadastroEtapa },
  name: 'CadastroServico',
  props: {
    formTitle: String,
    value: {
      type: Object,
      required: true,
    },
    tarifas: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      formError: false,
      dialogEtapa: false,
      formEtapa: {
        nome: null,
      },
      listEtapa: [],
      isEditEtapa: false,
    }
  },
  computed: {
    ...mapState('patio', ['etapas']),
    ...mapGetters('contratos', ['prestadoresServico']),
  },
  async mounted() {
    if (this.isEdit && this.value) {
      await this.getEtapas(this.value.id)
      if (this.etapas[this.value.id]) {
        this.listEtapa = [
          ...this.etapas[this.value.id].sort((a, b) =>
            a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0
          ),
        ]
      }
    } else {
      this.listEtapa = []
    }
  },
  methods: {
    ...mapActions('patio', [
      'getEtapas',
      'addEtapa',
      'editEtapa',
      'deleteEtapa',
    ]),
    ...mapActions('contratos', ['getContratos']),
    ...mapMutations('patio', ['deleteTodasEtapas']),

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    close() {
      Object.assign(this.value, this.defaultItem)
      this.$refs.observer.reset()
      this.$emit('close')
    },

    save() {
      this.$emit('save', this.value)

      this.$refs.observer.reset()
    },

    closeEtapa() {
      this.dialogEtapa = false
    },

    editItem(item) {
      this.formEtapa = { ...item }
      this.dialogEtapa = true
      this.isEditEtapa = true
    },

    async saveEtapa() {
      if (await this.$refs.cadastroEtapa.validate()) {
        if (!this.isEdit) {
          this.listEtapa.push(this.formEtapa)
        } else if (this.isEdit && this.value && this.isEditEtapa) {
          const index = this.listEtapa
            .map((etapa) => etapa.public_id)
            .indexOf(this.formEtapa.public_id)

          const nome = this.formEtapa.nome

          if (this.listEtapa[index].nome === this.formEtapa.nome)
            delete this.formEtapa.nome

          await this.editEtapa({
            id_servico: this.value.id,
            etapas: this.formEtapa,
          })
          this.listEtapa.splice(index, 1, { ...this.formEtapa, nome })
          this.isEditEtapa = false
        } else if (this.isEdit && this.value && !this.isEditEtapa) {
          const etapaAdicionada = await this.addEtapa({
            id_servico: this.value.id,
            etapas: this.formEtapa,
          })
          this.listEtapa.push({ ...etapaAdicionada })
        }

        this.refreshEtapa()
        this.dialogEtapa = false
      }
    },

    refreshEtapa() {
      this.formEtapa = {
        nome: null,
      }
    },

    refreshData(id_servico) {
      this.formEtapa = {
        nome: null,
      }
      this.listEtapa = []
      this.deleteTodasEtapas(id_servico)
    },

    async changeOrdemEtapa({ moved: { newIndex } }) {
      if (this.isEdit && this.value) {
        const dado = { ...this.listEtapa[newIndex], ordem: newIndex }
        delete dado.nome
        await this.editEtapa({ etapas: dado, id_servico: this.value.id })

        this.listEtapa = this.listEtapa.map((etapa, index) => {
          return {
            ...etapa,
            ordem: index,
          }
        })
      }
    },

    async removeEtapa(index) {
      if (this.isEdit && this.value) {
        await this.deleteEtapa({
          id_servico: this.value.id,
          id: this.listEtapa[index].public_id,
        })
      }
      this.listEtapa.splice(index, 1)
    },

    abrirFoto() {
      this.$refs['foto'].click()
    },

    changeArquivo(e) {
      const files = e.target.files

      this.value.foto = files[0]
    },
  },

  async created() {
    try {
      await this.getContratos()
    } catch (error) {
      this.errorMessage('Erro ao buscar contratos')
    }
  },
}
</script>
<style lang="scss">
.list-etapas {
  padding: auto;
  max-height: 200px;
  overflow-y: scroll;

  .etapa__header,
  .etapa {
    /* border-top: 1px solid; */
    border: 1px solid #949494;
    border-top: none;
    padding: 0 12px;
    display: grid;
    grid-template-columns: 2fr 7fr 1fr;
    text-transform: capitalize;

    p {
      margin: 0;
    }
  }

  .etapa {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .etapa:hover {
    background: #eee;
  }

  .etapa__header,
  .etapa {
    color: rgb(70 70 70);
  }

  .etapa__header p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 700;
  }

  .etapa__header,
  > div:first-child {
    border-top: 1px solid #949494;
  }
}
</style>
